import { IChartData, TMark } from "@types"
import { CanvasController } from "./_CanvasController"

interface IRegion {
  type: TMark
  start: number
  end: number
}

export class DataController {
  private _rawData: IChartData[] = []
  private _inPixelData: IChartData[] = []

  private _regions: Record<number, IRegion> = {}

  private isMinMaxSet = false
  private minVal = 0
  private maxVal = 0
  private minTime = 0
  private maxTime = 0

  constructor(
    private canvasController: CanvasController,
    initialData: IChartData[],
    minMax?: { minVal: number; maxVal: number }
  ) {
    if (minMax !== undefined) {
      this.minVal = minMax.minVal
      this.maxVal = minMax.maxVal
      this.isMinMaxSet = true
    }

    this.setData(initialData)
  }

  get rawData(): IChartData[] {
    return this._rawData
  }

  get inPixelData(): IChartData[] {
    return this._inPixelData
  }

  get regions(): Record<number, IRegion> {
    return this._regions
  }

  setData = (data: IChartData[]): void => {
    this._rawData = data
    this.setMinMax()
    this.parseRawToPixel()
  }

  addData = (data: IChartData[]): void => {
    const newDataLength = data.length

    this._rawData = this._rawData.slice(newDataLength - 1, -1).concat(data)
    this.setMinMax()
    this.parseRawToPixel()
  }

  private setMinMax(): void {
    if (this.isMinMaxSet) return

    // this.minTime = this._rawData[0].time
    // this.maxTime = this._rawData[this._rawData.length - 1].time

    const sorted = [...this._rawData].sort((a, b) => a.value - b.value)

    this.minVal = sorted[0].value
    this.maxVal = sorted[sorted.length - 1].value
  }

  resetMinMax = (minMax: { minVal: number; maxVal: number }): void => {
    this.minVal = minMax.minVal
    this.maxVal = minMax.maxVal
    this.isMinMaxSet = true
  }

  //   TODO парсинг времени
  parseRawToPixel = (): void => {
    this.canvasController.updatePlot()

    const centerValBias = (this.maxVal - this.minVal) / this.canvasController.plot.height
    const timeSlot = this.canvasController.plot.width / this._rawData.length

    const inPixelData: IChartData[] = []

    let isRegionOpen = false
    let key = 0
    const regions: Record<number, IRegion> = {}

    this._rawData.forEach(({ value, time, mark }, index) => {
      const _time = Math.round(index * timeSlot)

      const parsed = {
        value: Math.round(Math.abs((value - this.maxVal) / centerValBias) + this.canvasController.plot.y),
        time: _time,
      }

      inPixelData.push(parsed)

      if (isRegionOpen && (!mark || mark === "none")) {
        isRegionOpen = false
        key++
      }

      if (mark && mark !== "none") {
        isRegionOpen = true

        if (!regions[key]) regions[key] = { type: mark, start: _time, end: 0 }

        regions[key].end = _time
      }
    })

    this._inPixelData = inPixelData
    this._regions = regions
  }
}
