import React, { useEffect, useMemo, useState } from "react"
import { Stack, Typography, styled } from "@mui/material"

import { EMessageType, IObserver, IStation } from "@types"
import { useDemoScriptContext } from "@context"
import { useLatest } from "@utils"

interface IStationLabel {
  station: IStation
}

export const StationLabel: React.FC<IStationLabel> = ({ station }) => {
  const { demoScript } = useDemoScriptContext()

  const [isActive, setIsActive] = useState(station.isActive)

  const idRef = useLatest(station.id)

  const observers = useMemo(
    () => [
      {
        cb: (data: Partial<IStation>): void => {
          if (data.id !== idRef.current || data.isActive === undefined) return

          setIsActive(data.isActive)
        },
        type: EMessageType.STATION,
      },

      {
        cb: (): void => {
          setIsActive(station.isActive)
        },
        type: EMessageType.STOP,
      },
    ],
    []
  )

  useEffect(() => {
    demoScript.on(observers as IObserver<EMessageType>[])

    return () => demoScript.off(observers as IObserver<EMessageType>[])
  }, [])

  return (
    <SStationLabelWrapper isActive={isActive}>
      <Typography display="flex" justifyItems="flex-start" fontSize="14px" height="14px">
        {station.name}
      </Typography>
      <Typography variant="body2" fontSize="12px">
        {station.params}
      </Typography>
    </SStationLabelWrapper>
  )
}

const SStationLabelWrapper = styled(Stack, { shouldForwardProp: (prop) => prop !== "isActive" })<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    width: "80px",
    height: "42px",
    background: isActive ? theme.palette.error.dark : theme.palette.primary.main,
    borderRadius: "4px",
    padding: "5px",
    gap: "3px",
  })
)
