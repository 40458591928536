import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { styled } from "@mui/material"

import { WaveformCanvas, SignalDataProvider } from "./core"
import { DemoScript, WaveformLauncher, useLatest } from "@utils"
import { EMessageType, IChartData, IObserver } from "@types"
import { useModeContext } from "@context"

interface IWaveformCanvas {
  rootId: string | number
  signals: IChartData[]
  demoScript: DemoScript
  waveformLauncher: WaveformLauncher
}

export const WaveformCanvasComp: React.FC<IWaveformCanvas> = ({ rootId, signals, demoScript, waveformLauncher }) => {
  const { isDemo } = useModeContext()

  const id = rootId.toString()

  const waveformRef = useRef<WaveformCanvas | null>(null)
  const dataProviderRef = useRef<SignalDataProvider | null>(null)

  const minMax = useMemo(() => {
    const sortedArr = [...signals].sort((a, b) => a.value - b.value)

    return {
      minVal: sortedArr[0].value,
      maxVal: sortedArr[sortedArr.length - 1].value,
    }
  }, [signals])

  const signalsRef = useLatest({ signals: structuredClone(signals), minMax })

  const resetData = useCallback(() => {
    dataProviderRef.current?.setData(signalsRef.current.signals)
    waveformRef.current?.resetMinMax(signalsRef.current.minMax)
    waveformRef.current?.setData((dataProviderRef.current as SignalDataProvider).initialData)
  }, [])

  const observers = useMemo(
    () => [
      {
        type: EMessageType.TICK,
        cb: (): void => {
          dataProviderRef.current?.setChunk()
        },
      },
      {
        type: EMessageType.STOP,
        cb: (): void => {
          resetData()
        },
      },
    ],
    []
  )

  useEffect(() => {
    dataProviderRef.current = new SignalDataProvider(structuredClone(signals))
    waveformRef.current = new WaveformCanvas(id, dataProviderRef.current.initialData, minMax)

    dataProviderRef.current.addCb(waveformRef.current.addData)
    demoScript.on(observers as IObserver<EMessageType>[])
    waveformLauncher.on(observers as IObserver<EMessageType>[])

    return () => {
      demoScript.off(observers as IObserver<EMessageType>[])
      waveformLauncher.off(observers as IObserver<EMessageType>[])
      waveformRef.current?.dispose()
      dataProviderRef.current?.stop()
    }
  }, [])

  useEffect(() => {
    resetData()
  }, [minMax])

  useEffect(() => {
    if (!dataProviderRef.current) return

    dataProviderRef.current.mode = isDemo ? "demo" : "standby"
  }, [isDemo])

  return <SRoot id={id}></SRoot>
}

const SRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "42px",
  background: theme.palette.secondary.main,
}))
