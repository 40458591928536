interface ICanvasPlot {
  x: number
  y: number
  width: number
  height: number
}

export class CanvasController {
  private _canvas: HTMLCanvasElement
  private rootDiv: HTMLElement
  private _ctx: CanvasRenderingContext2D

  private _plotCoef = 0.9
  private _plot: ICanvasPlot = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }

  private timeoutId: NodeJS.Timer | null = null

  constructor(id: string, private resizeCb: () => void) {
    this.rootDiv = document.getElementById(id)!
    this.rootDiv.style.position = "relative"

    this._canvas = this.createCanvas()

    this._ctx = this._canvas.getContext("2d")!
    this.scaleContext()

    this.rootDiv.appendChild(this._canvas)

    window.addEventListener("resize", this.resizeListener)

    this.updatePlot()
  }

  get canvas(): HTMLCanvasElement {
    return this._canvas
  }

  get ctx(): CanvasRenderingContext2D {
    return this._ctx
  }

  get plot(): ICanvasPlot {
    return this._plot
  }

  set plotCoef(coef: number) {
    this._plotCoef = coef
  }

  private createCanvas(): HTMLCanvasElement {
    const canvas = document.createElement("canvas")
    canvas.style.position = "absolute"
    canvas.style.top = "0"
    canvas.style.left = "0"
    canvas.width = this.rootDiv.offsetWidth
    canvas.height = this.rootDiv.offsetHeight

    return canvas
  }

  private scaleContext(): void {
    // this._ctx.scale(devicePixelRatio, devicePixelRatio)
  }

  private resizeListener = (): void => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }

    this.timeoutId = setTimeout(() => {
      this._canvas.width = this.rootDiv.clientWidth
      this.scaleContext()
      this.resizeCb()
      this.timeoutId = null
    }, 50)
  }

  updatePlot(): void {
    const { height, width } = this.canvas

    this._plot = {
      x: 0,
      y: Math.round((height - height * this._plotCoef) / 2),
      width: width,
      height: Math.round(height * this._plotCoef),
    }
  }

  dispose(): void {
    window.removeEventListener("resize", this.resizeListener)
  }
}
