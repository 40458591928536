import { CanvasController } from "./_CanvasController"
import { DataController } from "./_DataController"

export class Drawer {
  private hasPathChanged = false

  private chartPath: Path2D | null = null

  constructor(private canvasController: CanvasController, private dataController: DataController) {
    this.update()
  }

  clear = (): void => {
    this.canvasController.ctx.clearRect(0, 0, this.canvasController.canvas.width, this.canvasController.canvas.height)
  }

  update = (): void => {
    this.updateChartPath()
  }

  drawChart = (): void => {
    if (!this.hasPathChanged) return

    this.clear()

    this.canvasController.ctx.fillStyle = "black"
    this.canvasController.ctx.lineWidth = 1

    this.drawRegions()

    this.chartPath && this.canvasController.ctx.stroke(this.chartPath)
    this.hasPathChanged = false
  }

  drawRegions = (): void => {
    const { height } = this.canvasController.canvas

    Object.values(this.dataController.regions).forEach(({ start, end, type }) => {
      this.canvasController.ctx.fillStyle = type === "alarm" ? "rgba(255, 0, 0, 0.4)" : "rgba(255, 255, 0, 0.4)"

      this.canvasController.ctx.fillRect(start, 0, end - start, height)
    })
  }

  drawBorder = (): void => {
    this.clear()

    const { x, y, width, height } = this.canvasController.plot

    this.canvasController.ctx.fillStyle = "red"
    this.canvasController.ctx.lineWidth = 1
    this.canvasController.ctx.fillRect(x, y, width, height)
  }

  private updateChartPath = (): void => {
    const { height } = this.canvasController.canvas

    this.chartPath = new Path2D()

    this.chartPath.moveTo(0, height / 2)

    this.dataController.inPixelData.forEach((d) => {
      this.chartPath!.lineTo(d.time, d.value)
    })

    this.hasPathChanged = true
  }
}
