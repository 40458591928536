import { IChartData } from "@types"

export class SignalDataProvider {
  private slices: Record<number, IChartData[]>
  private sliceIndex = 1
  private chunkIndex = 0

  private chunkSize = 100

  private intervalId: NodeJS.Timer | null = null

  private _mode: "demo" | "standby" = "standby"

  private cb: ((chunk: IChartData[]) => void) | null = null

  constructor(data: IChartData[]) {
    this.slices = chunkize(data)
  }

  get initialData(): IChartData[] {
    return this.slices[0]
  }

  set mode(mode: "demo" | "standby") {
    this._mode = mode
  }

  addCb = (cb: (chunk: IChartData[]) => void): void => {
    this.cb = cb
  }

  start(): void {
    if (this.intervalId) return

    this.intervalId = setInterval(() => {
      if (this.slices[this.sliceIndex] === undefined) {
        this.sliceIndex = 0
        this.chunkIndex = 0
      }

      this.setChunk()
    }, 1000)
  }

  setChunk = (): void => {
    if (this.slices[this.sliceIndex] === undefined) {
      if (this._mode === "standby") {
        this.sliceIndex = 0
        this.chunkIndex = 0
      } else return
    }

    const start = this.chunkIndex * this.chunkSize
    const end = (this.chunkIndex + 1) * this.chunkSize

    const chunk: IChartData[] = this.slices[this.sliceIndex].slice(start, end)
    this.cb && this.cb(chunk)

    this.chunkIndex++

    if (end >= this.slices[this.sliceIndex].length) {
      this.chunkIndex = 0
      this.sliceIndex++
    }
  }

  stop(): void {
    if (!this.intervalId) return

    clearInterval(this.intervalId)
    this.intervalId = null
  }

  setData(data: IChartData[]): void {
    this.stop()
    this.reset()
    this.slices = chunkize(data)
  }

  reset = (): void => {
    this.sliceIndex = 1
    this.chunkIndex = 0
  }
}

function chunkize(data: IChartData[]): Record<number, IChartData[]> {
  const chunkSize = 6000
  const chunkCount = Math.round(data.length / chunkSize)

  const slices: Record<number, IChartData[]> = {}

  for (let i = 0; i < chunkCount; i++) {
    slices[i] = data.slice(i * chunkSize, (i + 1) * chunkSize)
  }

  return slices
}
