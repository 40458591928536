import React, { memo } from "react"
import { Stack, styled } from "@mui/material"

import { WaveformCanvasComp } from "./fragments"
import { IStation } from "@types"
import { StationLabel } from "./fragments"
import { useDemoScriptContext } from "@context"

const WaveformCanvasCompMemo = memo(WaveformCanvasComp)

interface IStationLabel {
  station: IStation
}

export const StationComp: React.FC<IStationLabel> = ({ station }) => {
  const { demoScript, waveformLauncher, signals } = useDemoScriptContext()

  return (
    <SSignalItemContainer>
      <StationLabel station={station} />
      <WaveformCanvasCompMemo
        rootId={station.name}
        signals={signals[station.name]}
        demoScript={demoScript}
        waveformLauncher={waveformLauncher}
      />
    </SSignalItemContainer>
  )
}

const SSignalItemContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
})
