import React, { useMemo } from "react"
import { Stack, styled } from "@mui/material"

import { Cover } from "@components"
import { StationComp } from "./station_comp"
import { useLocalizationContext, useDemoScriptContext } from "@context"

export const Signals: React.FC = () => {
  const {
    l: {
      signals: { title },
    },
  } = useLocalizationContext()

  const { stations } = useDemoScriptContext()

  const stationsList = useMemo(() => stations.map((st) => <StationComp key={st.name} station={st} />), [])

  return (
    <Cover title={title}>
      <SContainer>{stationsList}</SContainer>
    </Cover>
  )
}

const SContainer = styled(Stack)({
  padding: "20px",
  height: "100%",
  gap: "8px",
  overflowY: "auto",
  paddingRight: "15px",
})
