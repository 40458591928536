export class OptimizedRAF {
  private time = 0
  private rate = 1000 / 60
  private RAFID = 0

  constructor(private draw: () => void, private printFPS?: boolean) {
    this.start()
  }

  private startAnimation = (timestamp: number): void => {
    const delta = timestamp - this.time

    this.RAFID = requestAnimationFrame(this.startAnimation)

    if (delta < this.rate) return

    this.draw()
    this.time = timestamp
  }

  start(): void {
    this.RAFID = requestAnimationFrame(this.startAnimation)
  }

  stop(): void {
    cancelAnimationFrame(this.RAFID)
  }
}
