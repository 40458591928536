import { IChartData } from "@types"
import { CanvasController } from "./_CanvasController"
import { DataController } from "./_DataController"
import { OptimizedRAF } from "./_OptimizedRAF"
import { Drawer } from "./_Drawer"

export class WaveformCanvas {
  private canvasController: CanvasController
  private dataController: DataController
  private drawer: Drawer
  private optimizedRAF: OptimizedRAF

  constructor(id: string, data: IChartData[], minMax: { minVal: number; maxVal: number }) {
    this.canvasController = new CanvasController(id, this.update)
    this.dataController = new DataController(this.canvasController, data, minMax)
    this.drawer = new Drawer(this.canvasController, this.dataController)
    this.optimizedRAF = new OptimizedRAF(this.draw)
  }

  private draw = (): void => {
    this.drawer.drawChart()
  }

  private update = (): void => {
    this.dataController.parseRawToPixel()
    this.drawer.update()
  }

  setData(data: IChartData[]): void {
    this.dataController.setData(data)
    this.drawer.update()
  }

  addData = (data: IChartData[]): void => {
    this.dataController.addData(data)
    this.drawer.update()
  }

  resetMinMax = (minMax: { minVal: number; maxVal: number }): void => {
    this.dataController.resetMinMax(minMax)
  }

  dispose(): void {
    this.optimizedRAF.stop()
    this.canvasController.dispose()
  }
}
